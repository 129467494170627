.listItemAspect {
  position: relative;
  width: calc(0.9 * 100%);
  height: 0px;
  padding-bottom: calc(0.9 * var(--mainPlayerAspect) * 100%);
}

.listItem iframe,
.listItem img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 1px solid #ddd;
}

.listItem img:hover {
  cursor: pointer;
}

.listItem .text {
  text-align: left;
  width: 100%;
  padding: 1em 0;
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
}

.listItem .title {
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  padding: 0;
}

.listItem .clickableTitle:hover {
  cursor: pointer;
}

.listItem .info {
  color: #999;
  padding-top: 0;
}

.listItem .subFamily {
  color: #9b59b6;
}

.listItem .subFamily:hover {
  cursor: pointer;
}