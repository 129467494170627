body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  word-wrap: break-word;
}

:root {
  --mainFont: 'Montserrat';
  --mainFontSize: 15px;
  --topMenuHeight: 46px;
  --topMenuWidth: 0.65;
  --mainColumnWidth: 0.65;
  --logoWidth: 0.1;
  --logoAspect: calc(36 / 170);
  --bannerAspect: calc(9 / 100);
  --footerAspect: calc(1 / 2);
  --mainPlayerAspect: calc(9 / 16);
  --main-bg-color: #1bb9d5;
  --main-bg-hover-color: #24cdea;
  /* --programmationClock: url('/resources/programmationClock.png'); */
}