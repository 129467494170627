
.LoginFormContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba( 180, 180, 180, 1);
}

.LoginForm-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 400px;
  transform: translate(-50%, -50%);
}
.LoginForm-centered hr {
  width: 100%;
  border-color: white;
}
.LoginForm-centered .form-group {
  width: 100%;
}

.LoginForm-logo {
  position: relative;
  width: 96px;
  height: 96px;
  left: 50%;
  transform: translate(-50%);
}

.LoginForm-background {
  background: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  padding: 30px;
  width: 100%;
  max-width: 800px;
}

.LoginForm-buttons {
  display: flow-root;
}

.LoginForm-buttons .btn-primary{
  background-color: var(--main-bg-color);
  border-color: var(--main-bg-color);
}
.LoginForm-buttons .btn-primary:hover {
  background-color: var(--main-bg-hover-color);
  border-color: var(--main-bg-hover-color);
}

.LoginForm-buttons .btn-cancelInForm{
  color: var(--main-bg-color);
}
.LoginForm-buttons .btn-cancelInForm:hover {
  color: var(--main-bg-hover-color);
}
