.SessionButtonsContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: 0px;
  text-align: center;
  line-height: 1.0;
}

.SessionButton {
  color: black;
  position: relative;
  top: 50%;
  transform: translate(0px, -50%);
  padding: 2px 4px;
  margin: 0px 2px;
  font-size: var(--mainFontSize);
  background-color: var(--main-bg-color);
  border: none;
}

.SessionButton:hover {
  color: black;
  font-weight: bold;
  background-color: var(--main-bg-hover-color);
}