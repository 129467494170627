.mainBackground {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  font-family: var(--mainFont);
  font-size: var(--mainFontSize);
  min-height: 100vh;
}

.topBannerBackground {
  background-color: black;
}

.margin {
  width: 100%;
  height: 2px;
}

.topBanner {
  width: calc(var(--mainColumnWidth) * 100%);
  height: 0px;
  padding-bottom: calc(var(--mainColumnWidth) * var(--bannerAspect) * 100%);
  position: relative;
  margin-left: calc((1 - var(--mainColumnWidth)) / 2 * 100%);
  background-color: white;
}

.header-text {
  text-align: center;
  margin: 30px 10px;
  font-size: 1.5em;
}

.header-text :first-child {
  font-weight: bold;
}

.middleBanner {
  width: calc(var(--mainColumnWidth) * 100%);
  height: 0px;
  padding-bottom: calc(var(--mainColumnWidth) * var(--bannerAspect) * 100%);
  position: relative;
  margin-left: calc((1 - var(--mainColumnWidth)) / 2 * 100%);
  background-color: white;
}

.footer-img {
  width: calc(var(--mainColumnWidth) * 100%);
  height: 0px;
  padding-bottom: calc(var(--mainColumnWidth) * var(--footerAspect) * 100%);
  position: relative;
  margin-left: calc((1 - var(--mainColumnWidth)) / 2 * 100%);
  background-color: white;
}

.footer-text {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: calc(1/6 * 100%) 10px 10px 10px;
  font-size: 0.8em;
  width: calc(var(--mainColumnWidth) * 100%);
  margin-left: auto;
  margin-right: auto;
}

.footer-text :first-child p {
  width: 100%;
  text-align: left;
}

.footer-text :nth-child(2) p {
  width: 100%;
  text-align: right;
}

.marginFooter {
  width: 100%;
  height: 10px;
}

.topBanner img,
.middleBanner img,
.footer-img img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}