
.programmationGridContainer {
    width: calc( var(--mainColumnWidth) * 100%);
    padding-bottom: calc(var(--mainColumnWidth) * var(--mainPlayerAspect) * 100%);
    padding-left: 18px;
    padding-right: 18px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    box-sizing: border-box;
    background-color: rgb(243,243,243);
  }

  .programmationGridContainer > div > .listRow {
    margin-top: 1.5em;
  }
  
  .hour {
    width: auto;
    height: auto;
    position: absolute;
    z-index: 86;
    background: #16baff;
    font-size: 9px;
    color: #fff;
    padding: 3px 10px;
    padding-right: 7px;
    margin-left: -4px;
    margin-top: 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    box-shadow: 2px 2px 7px -2px #000;
  }
  
  .hour i {
    background: var(--programmationClock) no-repeat center center;
    width: 13px;
    height: 13px;
    position: relative;
    display: block;
    float: left;
    margin-right: 4px;
    margin-left: -6px;
    margin-top: 0px;
  }
  
  .currentHour {
    background: #79aa00;
  }